/**
 * 日期：2022/4/8 16:25
 * author：lsshu
 **/
import CryptoJS from "crypto-js";
import { CrypotoType } from "./types";

class Crypoto implements CrypotoType {
  private key = "a"; // 要跟后端约定规则
  private keyHex = this.getHexKey();

  private getHexKey() {
    return CryptoJS.enc.Utf8.parse(this.key);
  }

  encrypt(content: any): string {
    return CryptoJS.AES.encrypt(JSON.stringify(content), this.key, {
      iv: this.keyHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  }

  decrypt(content: any) {
    const bytes = CryptoJS.AES.decrypt(content, this.key, {
      iv: this.keyHex,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return JSON.parse(bytes);
  }

  md5(content: any): string {
    return CryptoJS.MD5(JSON.stringify(content)).toString().toUpperCase();
  }

  private getParse(value: any) {
    return CryptoJS.enc.Utf8.parse(value);
  }

  Encrypt(content: any, key: string | undefined = "Lsshu", iv: string | undefined = undefined): string {
    const _key = this.getParse(key);
    return CryptoJS.AES.encrypt(JSON.stringify(content), _key, {
      iv: this.getParse(iv || _key),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString();
  }

  Decrypt(content: any, key: string | undefined = "Lsshu", iv: string | undefined = undefined) {
    const _key = this.getParse(key);
    const bytes = CryptoJS.AES.decrypt(content, _key, {
      iv: this.getParse(iv || _key),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    }).toString(CryptoJS.enc.Utf8);
    return JSON.parse(bytes);
  }
}

export default new Crypoto();
