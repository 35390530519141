/**
 * 日期：2022/5/12 16:00
 * author：lsshu
 **/
import { Module } from "vuex";
import { IRootState } from "../../types";
import { IGlobalState } from "./types";
import localCache from "@/utils/localcache";
import { KEY_TEXT } from "@/constant/config";
const globalModule: Module<IGlobalState, IRootState> = {
  namespaced: true,
  state() {
    return {
      tags_view: localCache.get(KEY_TEXT.KEY_TAG_VIEWS) || []
    };
  },
  mutations: {
    addTagsView(state, tag) {
      const isFind = state.tags_view.find((item: any) => {
        return item.path === tag.path;
      });
      // 处理重复
      if (!isFind) {
        state.tags_view.push(tag);
        localCache.set(KEY_TEXT.KEY_TAG_VIEWS, state.tags_view);
      }
    }
  }
};
export default globalModule;
