/**
 * 日期：2022/5/29 11:20
 * author：lsshu
 **/
import { App } from "vue";
import initStore from "./initial/store";
import registerProperties from "./register/properties";
import VueUeditorWrap from "vue-ueditor-wrap";
import jquery from "jquery";
// import Particles from "vue3-particles";

export default function globalRegister(app: App): void {
  app.use(initStore);
  app.use(registerProperties);
  app.use(VueUeditorWrap);
  app.use(jquery);
}
