/**
 * 日期：2022/4/30 16:39
 * author：lsshu
 * set:设置缓存
 * get:获取缓存
 * delete:删除缓存
 * clear:清空缓存
 **/
import Crypoto from "../crypto";

const DEFAULT_ENCRYPTION = false; // 默认是否加密
class LocalCache {
  set(key: string, value: any, encryption = DEFAULT_ENCRYPTION) {
    key = !encryption ? key : Crypoto.md5(key);
    value = !encryption ? value : Crypoto.encrypt(value);
    window.localStorage.setItem(key, JSON.stringify(value));
  }

  get(key: string, encryption = DEFAULT_ENCRYPTION) {
    key = !encryption ? key : Crypoto.md5(key);
    const value = window.localStorage.getItem(key);
    if (value) {
      return !encryption ? JSON.parse(value) : Crypoto.decrypt(JSON.parse(value));
    }
  }

  delete(key: string, encryption = DEFAULT_ENCRYPTION) {
    key = !encryption ? key : Crypoto.md5(key);
    window.localStorage.removeItem(key);
  }

  clear() {
    window.localStorage.clear();
  }
}

export default new LocalCache();
