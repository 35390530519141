/**
 * 日期：2022/5/31 13:58
 * author：lsshu
 **/
import { IForm } from "components/form/types";

export const pageConfig: IForm = {
  formProps: {
    labelWidth: "95px"
  },
  rowItems: [
    {
      colItems: [
        {
          field: "title",
          type: "LInput",
          itemProps: {
            label: "页面标题",
            prop: "title"
          },
          formProps: {
            maxlength: 190
          },
          colProps: {
            lg: 24,
            md: 24,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "keywords",
          type: "LInput",
          itemProps: {
            label: "页面关键词",
            prop: "keywords"
          },
          formProps: {
            maxlength: 190
          },
          colProps: {
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "description",
          type: "LInput",
          itemProps: {
            label: "页面描述",
            prop: "description"
          },
          formProps: {
            maxlength: 190
          },
          colProps: {
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24
          }
        }
      ]
    },
    {
      colItems: [
        {
          field: "departure_time",
          type: "LInput",
          itemProps: {
            label: "出发时间",
            prop: "departure_time"
          },
          formProps: {
            maxlength: 10
          },
          colProps: {
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "per_cost",
          type: "LInput",
          itemProps: {
            label: "人均费用",
            prop: "per_cost"
          },
          formProps: {
            maxlength: 10
          },
          colProps: {
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "travel_character",
          type: "LInput",
          itemProps: {
            label: "出行人物",
            prop: "travel_character"
          },
          formProps: {
            maxlength: 10
          },
          colProps: {
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "travel_days",
          type: "LInput",
          itemProps: {
            label: "出行天数",
            prop: "travel_days"
          },
          formProps: {
            maxlength: 10
          },
          colProps: {
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24
          }
        }
      ]
    },
    {
      colItems: [
        {
          field: "contact_name_color",
          type: "LColorPicker",
          itemProps: {
            label: "联系人颜色",
            prop: "contact_name_color"
          },
          formProps: {
            maxlength: 10
          },
          colProps: {
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "contact_number_color",
          type: "LColorPicker",
          itemProps: {
            label: "联系号颜色",
            prop: "contact_number_color"
          },
          formProps: {
            maxlength: 10
          },
          colProps: {
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "top_image",
          type: "LImage",
          itemProps: {
            label: "顶部大图",
            prop: "top_image",
            preview: "top_image_path"
          },
          colProps: {
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "logo_image",
          type: "LImage",
          itemProps: {
            label: "LOGO",
            prop: "logo_image",
            preview: "logo_image_path"
          },
          colProps: {
            lg: 12,
            md: 12,
            sm: 24,
            xs: 24
          }
        }
      ]
    },
    {
      colItems: [
        {
          field: "describe",
          type: "LUEditor",
          itemProps: {
            label: "描述",
            prop: "describe",
            position: "under",
            toolbar: "miniCustom",
            init: true
          },
          colProps: {
            lg: 24,
            md: 24,
            sm: 24,
            xs: 24
          }
        }
      ]
    }
  ]
};
export const publisherConfig: IForm = {
  rowItems: [
    {
      colItems: [
        {
          field: "publisher",
          type: "LObject",
          itemProps: {
            label: "",
            prop: "publisher"
          },
          formProps: {
            rowItems: [
              {
                colItems: [
                  {
                    field: "name",
                    type: "LInput",
                    itemProps: {
                      label: "名称",
                      prop: "name"
                    },
                    colProps: {
                      lg: 8,
                      md: 8,
                      sm: 24,
                      xs: 24
                    }
                  },
                  {
                    field: "image",
                    type: "LImage",
                    itemProps: {
                      label: "头像",
                      prop: "image",
                      preview: "image_path"
                    },
                    colProps: {
                      lg: 8,
                      md: 8,
                      sm: 24,
                      xs: 24
                    }
                  },
                  {
                    field: "humens",
                    type: "LSelect",
                    itemProps: {
                      label: "评客",
                      prop: "humens"
                    },
                    formProps: {
                      options: []
                    },
                    colProps: {
                      lg: 8,
                      md: 8,
                      sm: 24,
                      xs: 24
                    }
                  },
                  {
                    field: "image_path",
                    type: "LHidden",
                    itemProps: {
                      label: "头像视图",
                      prop: "image_path"
                    },
                    colProps: {
                      lg: 8,
                      md: 8,
                      sm: 24,
                      xs: 24
                    }
                  }
                ]
              },
              {
                colItems: [
                  {
                    field: "level",
                    type: "LInput",
                    itemProps: {
                      label: "等级",
                      prop: "level"
                    },
                    colProps: {
                      lg: 6,
                      md: 6,
                      sm: 24,
                      xs: 24
                    }
                  },
                  {
                    field: "time",
                    type: "LInput",
                    itemProps: {
                      label: "时间",
                      prop: "time"
                    },
                    colProps: {
                      lg: 6,
                      md: 6,
                      sm: 24,
                      xs: 24
                    }
                  },
                  {
                    field: "tag",
                    type: "LInput",
                    itemProps: {
                      label: "标签",
                      prop: "tag"
                    },
                    colProps: {
                      lg: 6,
                      md: 6,
                      sm: 24,
                      xs: 24
                    }
                  }
                ]
              },
              {
                colItems: [
                  {
                    field: "crown",
                    type: "LInput",
                    itemProps: {
                      label: "顶踩",
                      prop: "crown"
                    },
                    colProps: {
                      lg: 6,
                      md: 6,
                      sm: 24,
                      xs: 24
                    }
                  },
                  {
                    field: "thank",
                    type: "LInput",
                    itemProps: {
                      label: "感谢",
                      prop: "thank"
                    },
                    colProps: {
                      lg: 6,
                      md: 6,
                      sm: 24,
                      xs: 24
                    }
                  },
                  {
                    field: "collect",
                    type: "LInput",
                    itemProps: {
                      label: "收藏",
                      prop: "collect"
                    },
                    colProps: {
                      lg: 6,
                      md: 6,
                      sm: 24,
                      xs: 24
                    }
                  },
                  {
                    field: "ad",
                    type: "LInput",
                    itemProps: {
                      label: "@的",
                      prop: "ad"
                    },
                    colProps: {
                      lg: 6,
                      md: 6,
                      sm: 24,
                      xs: 24
                    }
                  }
                ]
              }
            ]
          },
          colProps: {
            lg: 24,
            md: 24,
            sm: 24,
            xs: 24
          }
        }
      ]
    }
  ]
};
export const commentConfig: IForm = {
  rowItems: [
    {
      colItems: [
        {
          field: "name",
          type: "LInput",
          itemProps: {
            label: "名称",
            prop: "name"
          },
          colProps: {
            lg: 8,
            md: 8,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "image",
          type: "LImage",
          itemProps: {
            label: "头像",
            prop: "image",
            preview: "image_path"
          },
          colProps: {
            lg: 8,
            md: 8,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "humens",
          type: "LSelect",
          itemProps: {
            label: "评客",
            prop: "humens"
          },
          formProps: {
            options: []
          },
          colProps: {
            lg: 8,
            md: 8,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "image_path",
          type: "LHidden",
          itemProps: {
            label: "头像视图",
            prop: "image_path"
          },
          colProps: {
            lg: 8,
            md: 8,
            sm: 24,
            xs: 24
          }
        }
      ]
    },
    {
      colItems: [
        {
          field: "level",
          type: "LInput",
          itemProps: {
            label: "等级",
            prop: "level"
          },
          colProps: {
            lg: 6,
            md: 6,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "time",
          type: "LInput",
          itemProps: {
            label: "时间",
            prop: "time"
          },
          colProps: {
            lg: 6,
            md: 6,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "tag",
          type: "LInput",
          itemProps: {
            label: "标签",
            prop: "tag"
          },
          colProps: {
            lg: 6,
            md: 6,
            sm: 24,
            xs: 24
          }
        }
      ]
    },
    {
      colItems: [
        {
          field: "crown",
          type: "LInput",
          itemProps: {
            label: "顶踩",
            prop: "crown"
          },
          colProps: {
            lg: 6,
            md: 6,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "thank",
          type: "LInput",
          itemProps: {
            label: "感谢",
            prop: "thank"
          },
          colProps: {
            lg: 6,
            md: 6,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "collect",
          type: "LInput",
          itemProps: {
            label: "收藏",
            prop: "collect"
          },
          colProps: {
            lg: 6,
            md: 6,
            sm: 24,
            xs: 24
          }
        },
        {
          field: "ad",
          type: "LSelect",
          itemProps: {
            label: "@的",
            prop: "ad"
          },
          formProps: {
            options: []
          },
          colProps: {
            lg: 6,
            md: 6,
            sm: 24,
            xs: 24
          }
        }
      ]
    },
    {
      colItems: [
        {
          field: "content",
          type: "LUEditor",
          itemProps: {
            label: "",
            prop: "content",
            position: "under",
            toolbar: "miniCustom",
            init: true
          },
          colProps: {
            lg: 24,
            md: 24,
            sm: 24,
            xs: 24
          }
        }
      ]
    }
  ]
};
export const rightConfig: IForm = {
  rowItems: [
    {
      colItems: [
        {
          field: "ri",
          type: "LListObject",
          itemProps: {
            label: "图片",
            prop: "ri"
          },
          formProps: {
            rowItems: [
              {
                colItems: [
                  {
                    field: "title",
                    type: "LInput",
                    itemProps: {
                      label: "标题",
                      prop: "title"
                    },
                    colProps: {
                      lg: 12,
                      md: 12,
                      sm: 24,
                      xs: 24
                    }
                  },
                  {
                    field: "image",
                    type: "LImage",
                    itemProps: {
                      label: "图片",
                      prop: "image",
                      preview: "image_path"
                    },
                    colProps: {
                      lg: 12,
                      md: 12,
                      sm: 24,
                      xs: 24
                    }
                  }
                ]
              }
            ]
          },
          colProps: {
            lg: 24,
            md: 24,
            sm: 24,
            xs: 24
          }
        }
      ]
    },
    {
      colItems: [
        {
          field: "rt",
          type: "LListObject",
          itemProps: {
            label: "文本",
            prop: "rt"
          },
          formProps: {
            rowItems: [
              {
                colItems: [
                  {
                    field: "title",
                    type: "LInput",
                    itemProps: {
                      label: "标题",
                      prop: "title"
                    },
                    colProps: {
                      lg: 12,
                      md: 12,
                      sm: 24,
                      xs: 24
                    }
                  },
                  {
                    field: "url",
                    type: "LInput",
                    itemProps: {
                      label: "地址",
                      prop: "url"
                    },
                    colProps: {
                      lg: 12,
                      md: 12,
                      sm: 24,
                      xs: 24
                    }
                  }
                ]
              }
            ]
          },
          colProps: {
            lg: 24,
            md: 24,
            sm: 24,
            xs: 24
          }
        }
      ]
    },
    {
      colItems: [
        {
          field: "rc",
          type: "LUEditor",
          itemProps: {
            label: "右边",
            prop: "rc",
            position: "under",
            toolbar: "miniCustom",
            init: true
          },
          colProps: {
            lg: 24,
            md: 24,
            sm: 24,
            xs: 24
          }
        }
      ]
    }
  ]
};
