export const beforeInitFunction = (editorId: string, config: any) => {
  (window as any).UE.registerUI(
    "province",
    (editor: any, uiName: any) => {
      // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand() {
          editor.execCommand("inserthtml", `「访客所在省」`);
        }
      });

      // 创建一个 button
      const btn = new (window as any).UE.ui.Button({
        // 按钮的名字
        name: uiName,
        // 提示
        title: "访客当前所在的省份",
        // 需要添加的额外样式，可指定 icon 图标
        cssRules: `background-image: url('${config.UEDITOR_HOME_URL}custom/province.png') !important; background-size: cover;`,
        // 点击时执行的命令
        onclick() {
          // 这里可以不用执行命令，做你自己的操作也可
          editor.execCommand(uiName);
        }
      });

      // 当点到编辑内容上时，按钮要做的状态反射
      editor.addListener("selectionchange", function () {
        const state = editor.queryCommandState(uiName);
        if (state === -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });

      // 因为你是添加 button，所以需要返回这个 button
      return btn;
    }
    // 0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */,
    // editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
  );
  (window as any).UE.registerUI(
    "city",
    (editor: any, uiName: any) => {
      // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand() {
          editor.execCommand("inserthtml", `「访客所在市」`);
        }
      });

      // 创建一个 button
      const btn = new (window as any).UE.ui.Button({
        // 按钮的名字
        name: uiName,
        // 提示
        title: "访客当前所在的城市",
        // 需要添加的额外样式，可指定 icon 图标
        cssRules: `background-image: url('${config.UEDITOR_HOME_URL}custom/city.png') !important; background-size: cover;`,
        // 点击时执行的命令
        onclick() {
          // 这里可以不用执行命令，做你自己的操作也可
          editor.execCommand(uiName);
        }
      });

      // 当点到编辑内容上时，按钮要做的状态反射
      editor.addListener("selectionchange", function () {
        const state = editor.queryCommandState(uiName);
        if (state === -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });

      // 因为你是添加 button，所以需要返回这个 button
      return btn;
    }
    // 0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */,
    // editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
  );
  (window as any).UE.registerUI(
    "nickname",
    (editor: any, uiName: any) => {
      // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand() {
          editor.execCommand("inserthtml", `「客服昵称」`);
        }
      });

      // 创建一个 button
      const btn = new (window as any).UE.ui.Button({
        // 按钮的名字
        name: uiName,
        // 提示
        title: "客服昵称",
        // 需要添加的额外样式，可指定 icon 图标
        cssRules: `background-image: url('${config.UEDITOR_HOME_URL}custom/nickname.png') !important; background-size: cover;`,
        // 点击时执行的命令
        onclick() {
          // 这里可以不用执行命令，做你自己的操作也可
          editor.execCommand(uiName);
        }
      });

      // 当点到编辑内容上时，按钮要做的状态反射
      editor.addListener("selectionchange", function () {
        const state = editor.queryCommandState(uiName);
        if (state === -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });

      // 因为你是添加 button，所以需要返回这个 button
      return btn;
    }
    // 0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */,
    // editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
  );
  (window as any).UE.registerUI(
    "auto",
    (editor: any, uiName: any) => {
      // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand() {
          editor.execCommand("inserthtml", `「客服微信/手机」`);
        }
      });

      // 创建一个 button
      const btn = new (window as any).UE.ui.Button({
        // 按钮的名字
        name: uiName,
        // 提示
        title: "自动选择微信/手机",
        // 需要添加的额外样式，可指定 icon 图标
        cssRules: `background-image: url('${config.UEDITOR_HOME_URL}custom/auto.png') !important; background-size: cover;`,
        // 点击时执行的命令
        onclick() {
          // 这里可以不用执行命令，做你自己的操作也可
          editor.execCommand(uiName);
        }
      });

      // 当点到编辑内容上时，按钮要做的状态反射
      editor.addListener("selectionchange", function () {
        const state = editor.queryCommandState(uiName);
        if (state === -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });

      // 因为你是添加 button，所以需要返回这个 button
      return btn;
    }
    // 0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */,
    // editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
  );
  (window as any).UE.registerUI(
    "phone",
    (editor: any, uiName: any) => {
      // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand() {
          editor.execCommand("inserthtml", `「客服电话」`);
        }
      });

      // 创建一个 button
      const btn = new (window as any).UE.ui.Button({
        // 按钮的名字
        name: uiName,
        // 提示
        title: "客服手机号码",
        // 需要添加的额外样式，可指定 icon 图标
        cssRules: `background-image: url('${config.UEDITOR_HOME_URL}custom/phone.png') !important; background-size: cover;`,
        // 点击时执行的命令
        onclick() {
          // 这里可以不用执行命令，做你自己的操作也可
          editor.execCommand(uiName);
        }
      });

      // 当点到编辑内容上时，按钮要做的状态反射
      editor.addListener("selectionchange", function () {
        const state = editor.queryCommandState(uiName);
        if (state === -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });

      // 因为你是添加 button，所以需要返回这个 button
      return btn;
    }
    // 0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */,
    // editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
  );
  (window as any).UE.registerUI(
    "wechat",
    (editor: any, uiName: any) => {
      // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand() {
          editor.execCommand("inserthtml", `「客服微信」`);
        }
      });

      // 创建一个 button
      const btn = new (window as any).UE.ui.Button({
        // 按钮的名字
        name: uiName,
        // 提示
        title: "客户微信号码",
        // 需要添加的额外样式，可指定 icon 图标
        cssRules: `background-image: url('${config.UEDITOR_HOME_URL}custom/wechat.png') !important; background-size: cover;`,
        // 点击时执行的命令
        onclick() {
          // 这里可以不用执行命令，做你自己的操作也可
          editor.execCommand(uiName);
        }
      });

      // 当点到编辑内容上时，按钮要做的状态反射
      editor.addListener("selectionchange", function () {
        const state = editor.queryCommandState(uiName);
        if (state === -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });

      // 因为你是添加 button，所以需要返回这个 button
      return btn;
    }
    // 0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */,
    // editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
  );
  (window as any).UE.registerUI(
    "sex",
    (editor: any, uiName: any) => {
      // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand() {
          editor.execCommand("inserthtml", `「客服性别」`);
        }
      });

      // 创建一个 button
      const btn = new (window as any).UE.ui.Button({
        // 按钮的名字
        name: uiName,
        // 提示
        title: "客服性别他/她",
        // 需要添加的额外样式，可指定 icon 图标
        cssRules: `background-image: url('${config.UEDITOR_HOME_URL}custom/sex.png') !important; background-size: cover;`,
        // 点击时执行的命令
        onclick() {
          // 这里可以不用执行命令，做你自己的操作也可
          editor.execCommand(uiName);
        }
      });

      // 当点到编辑内容上时，按钮要做的状态反射
      editor.addListener("selectionchange", function () {
        const state = editor.queryCommandState(uiName);
        if (state === -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });

      // 因为你是添加 button，所以需要返回这个 button
      return btn;
    }
    // 0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */,
    // editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
  );
  (window as any).UE.registerUI(
    "sex-",
    (editor: any, uiName: any) => {
      // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand() {
          editor.execCommand("inserthtml", `「客服帅美」`);
        }
      });

      // 创建一个 button
      const btn = new (window as any).UE.ui.Button({
        // 按钮的名字
        name: uiName,
        // 提示
        title: "性别为帅哥/美女",
        // 需要添加的额外样式，可指定 icon 图标
        cssRules: `background-image: url('${config.UEDITOR_HOME_URL}custom/sex-.png') !important; background-size: cover;`,
        // 点击时执行的命令
        onclick() {
          // 这里可以不用执行命令，做你自己的操作也可
          editor.execCommand(uiName);
        }
      });

      // 当点到编辑内容上时，按钮要做的状态反射
      editor.addListener("selectionchange", function () {
        const state = editor.queryCommandState(uiName);
        if (state === -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });

      // 因为你是添加 button，所以需要返回这个 button
      return btn;
    }
    // 0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */,
    // editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
  );
  (window as any).UE.registerUI(
    "sex--",
    (editor: any, uiName: any) => {
      // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand() {
          editor.execCommand("inserthtml", `「客服哥妹」`);
        }
      });

      // 创建一个 button
      const btn = new (window as any).UE.ui.Button({
        // 按钮的名字
        name: uiName,
        // 提示
        title: "性别为阿哥/阿妹",
        // 需要添加的额外样式，可指定 icon 图标
        cssRules: `background-image: url('${config.UEDITOR_HOME_URL}custom/sex--.png') !important; background-size: cover;`,
        // 点击时执行的命令
        onclick() {
          // 这里可以不用执行命令，做你自己的操作也可
          editor.execCommand(uiName);
        }
      });

      // 当点到编辑内容上时，按钮要做的状态反射
      editor.addListener("selectionchange", function () {
        const state = editor.queryCommandState(uiName);
        if (state === -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });

      // 因为你是添加 button，所以需要返回这个 button
      return btn;
    }
    // 0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */,
    // editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
  );
  (window as any).UE.registerUI(
    "code",
    (editor: any, uiName: any) => {
      // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand() {
          editor.execCommand("inserthtml", `「二维码」`);
        }
      });

      // 创建一个 button
      const btn = new (window as any).UE.ui.Button({
        // 按钮的名字
        name: uiName,
        // 提示
        title: "放二维码",
        // 需要添加的额外样式，可指定 icon 图标
        cssRules: `background-image: url('${config.UEDITOR_HOME_URL}custom/code.png') !important; background-size: cover;`,
        // 点击时执行的命令
        onclick() {
          // 这里可以不用执行命令，做你自己的操作也可
          editor.execCommand(uiName);
        }
      });

      // 当点到编辑内容上时，按钮要做的状态反射
      editor.addListener("selectionchange", function () {
        const state = editor.queryCommandState(uiName);
        if (state === -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });

      // 因为你是添加 button，所以需要返回这个 button
      return btn;
    }
    // 0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */,
    // editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
  );
  (window as any).UE.registerUI(
    "price",
    (editor: any, uiName: any) => {
      // 注册按钮执行时的 command 命令，使用命令默认就会带有回退操作
      editor.registerCommand(uiName, {
        execCommand() {
          editor.execCommand("inserthtml", `「人均费用」`);
        }
      });

      // 创建一个 button
      const btn = new (window as any).UE.ui.Button({
        // 按钮的名字
        name: uiName,
        // 提示
        title: "放人均费用",
        // 需要添加的额外样式，可指定 icon 图标
        cssRules: `background-image: url('${config.UEDITOR_HOME_URL}custom/price.png') !important; background-size: cover;`,
        // 点击时执行的命令
        onclick() {
          // 这里可以不用执行命令，做你自己的操作也可
          editor.execCommand(uiName);
        }
      });

      // 当点到编辑内容上时，按钮要做的状态反射
      editor.addListener("selectionchange", function () {
        const state = editor.queryCommandState(uiName);
        if (state === -1) {
          btn.setDisabled(true);
          btn.setChecked(false);
        } else {
          btn.setDisabled(false);
          btn.setChecked(state);
        }
      });

      // 因为你是添加 button，所以需要返回这个 button
      return btn;
    }
    // 0 /* 指定添加到工具栏上的哪个位置，默认时追加到最后 */,
    // editorId /* 指定这个 UI 是哪个编辑器实例上的，默认是页面上所有的编辑器都会添加这个按钮 */
  );
};
