/**
 * 日期：2022/4/30 17:12
 * author：lsshu
 **/
import Request from "@/utils/request";
import { BASE_URL, TIME_OUT } from "./config";
import { REQUEST, KEY_TEXT } from "@/constant/config";

import router from "@/router";
import { REDIRECT } from "@/constant/config";
import { ElNotification } from "element-plus";

import localCache from "@/utils/localcache";
import NProgress from "@/utils/progress";

const request = new Request({
  baseURL: BASE_URL,
  timeout: TIME_OUT,
  interceptors: {
    requestInterceptor: (config: any) => {
      NProgress.start();
      // 携带token的拦截
      const token = localCache.get(KEY_TEXT.KEY_TOKEN);
      if (token) {
        config.headers[`${REQUEST.REQUEST_AUTHORIZATION_KEY}`] = `${REQUEST.REQUEST_AUTHORIZATION_TYPE} ${token}`;
      }
      return config;
    },
    requestInterceptorCatch: (err) => {
      return err;
    },
    responseInterceptor: (res) => {
      NProgress.done();
      return res.data;
    },
    responseInterceptorCatch: async (err) => {
      NProgress.done();
      if (err.response.status === 401) {
        ElNotification({
          title: "Error",
          type: "error",
          message: `登录失效,请重新登录！, ${err.response.data.detail}.`
        });
        localCache.delete(KEY_TEXT.KEY_TOKEN);
        return router.push({ name: REDIRECT.SCOPE_401_REDIRECT_ROUTE_NAME });
      } else if (err.response.status === 422) {
        const detail: any = err.response.data.detail;
        ElNotification({
          title: `${detail[0].msg}`,
          type: "error",
          message: `${detail[0].loc.join(",")}.`
        });
      } else {
        ElNotification({
          title: "Error",
          type: "error",
          message: `错误的请求, ${err.response.data.detail}.`
        });
      }
      return err;
    }
  }
});

export default request;
