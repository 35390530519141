const formRulesMessage = (mode: string, itemProps: any, label = "") => {
  const rules =
    mode === "create" ? itemProps.createRules || itemProps.rules : mode === "edit" ? itemProps.editRules || itemProps.rules : itemProps.rules;

  if (rules) {
    rules.map((rule: any) => {
      if (rule.required === true && !rule.message) {
        rule.message = itemProps.label || label + " 不能为空！";
      }
    });
  }
  return rules;
};

export { formRulesMessage };
