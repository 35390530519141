import { App } from "vue";
import { formatUtcString, getDaysToDayBetween } from "@/utils/dateformat";

export default function registerProperties(app: App) {
  app.config.globalProperties.$filters = {
    formatTime(value: string, format: string) {
      return formatUtcString(value, format);
    },
    toToDays(value: string) {
      return getDaysToDayBetween(value);
    }
  };
}
