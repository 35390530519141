/**
 * 日期：2022/5/7 17:17
 * author：lsshu
 **/
/*登录相关*/
const LOGIN = {
  REQUEST_LOGIN_URI: "/token", // 登录地址
  REQUEST_GET_SCOPES_URI: "/scopes", // 获取授权列表
  REQUEST_GET_MENUS_URI: "/Yao.function.permissions.menus", // 获取用户菜单
  REQUEST_USER_URI: "/me" // 获取登录用户信息get 修改登录用户信息post
};
const AUTH = {
  USER: "Yao.function.users",
  LOG: "Yao.function.logs",
  QUEUE: "Yao.function.queues",
  COMPANY: "Yao.function.companies",
  DEPARTMENT: "Yao.function.departments",
  APPOINTMENT: "Yao.function.appointments",
  PERMISSION: "Yao.function.permissions",
  // PERMISSION_TREE: "Yao.function.permissions.tree",
  ANNEX: "Yao.function.annexes"
};
const UPLOAD = {
  IMAGE_ACTION: "Yao.function.annexes",
  UEDITOR_SERVER_URL: "Yao.function.annexes.ueditor"
};
export { LOGIN, AUTH, UPLOAD };
