/**
 * 日期：2022/5/7 17:28
 * author：lsshu
 **/
import request from "../request";
import { IResponseDataType } from "./types";
import { Method } from "axios";

/*获取数据列表*/
export function getData(url: string, params: any = {}) {
  return request.get<IResponseDataType>({
    url: url,
    params: params
  });
}

/*创建数据*/
export function postData(url: string, data: any, params: any = {}) {
  return request.post<IResponseDataType>({
    url: url,
    data: data,
    params: params
  });
}
/*更新数据*/
export function putData(url: string, data: any, params: any = {}) {
  return request.put<IResponseDataType>({
    url: url,
    data: data,
    params: params
  });
}
/*部分更新数据*/
export function patchData(url: string, data: any, params: any = {}) {
  return request.patch<IResponseDataType>({
    url: url,
    data: data,
    params: params
  });
}

/*删除数据*/
export function deleteData(url: string, data: any = {}, params: any = {}) {
  return request.delete<IResponseDataType>({
    url: url,
    data: data,
    params: params
  });
}

/*请求数据*/
export function requestData(url: string, method: Method | any, data: any = {}, params: any = {}) {
  return request.request<IResponseDataType>({
    method,
    url,
    data,
    params
  });
}
