import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import localCache from "@/utils/localcache";
import { KEY_TEXT } from "@/constant/config";
import LLayout from "@/components/layout";
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: "/main"
  },
  {
    path: "/main",
    name: "main",
    redirect: "/Dashboard",
    component: LLayout,
    children: [
      {
        path: "/Dashboard",
        name: "Dashboard",
        component: () => import("@/views/main/l-dashboard/l-dashboard.vue"),
        meta: { title: "Dashboard", icon: "dashboard" }
      }
    ]
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/yao/l-login/l-login.vue")
  },
  {
    path: "/:pathMatch(.*)*",
    name: "notFound",
    redirect: "/not-found",
    component: LLayout,
    children: [
      {
        path: "/not-found",
        name: "Not Found",
        component: () => import("@/views/yao/l-not-found/l-not-found.vue"),
        meta: { title: "Not Found", icon: "" }
      }
    ]
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach((to) => {
  (window as any).document.title = to.meta.title || to.meta.name ? (to.meta.title || to.meta.name) + "｜LSSHU系统" : "LSSHU系统";
  const token = localCache.get(KEY_TEXT.KEY_TOKEN);
  if (to.path !== "/login") {
    if (!token) return "/login";
  } else {
    if (token) return "/";
  }
});

export default router;
