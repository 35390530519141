/**
 * 日期：2022/5/12 14:46
 * author：lsshu
 **/
import { RouteRecordRaw } from "vue-router";
import { IBreadcrumb } from "components/layout/components/header/components/breadcrumb/types";

export function mapMenusToRoutes(userMenus: any[]): RouteRecordRaw[] {
  const routes: RouteRecordRaw[] = [];
  // 1.先去加载默认所有的routes
  const allRoutes: RouteRecordRaw[] = [];
  const routeFiles = require.context("../../router/main", true, /\.ts/);
  routeFiles.keys().forEach((key) => {
    const route = require("../../router/main" + key.split(".")[1]);
    allRoutes.push(route.default);
  });
  // 2.根据菜单获取需要添加的routes
  const _recurseGetRoute = (menus: any[]) => {
    for (const menu of menus) {
      if (!menu.children) {
        const route = allRoutes.find((route) => route.path === menu.path);
        if (route) {
          route.name = menu.scope;
          route.meta = menu;
          routes.push(route);
        }
      } else {
        _recurseGetRoute(menu.children);
      }
    }
  };
  _recurseGetRoute(userMenus);
  return routes;
}

export function pathMapBreadcrumbs(userMenus: any[], currentPath: string) {
  const breadcrumbs: IBreadcrumb[] = [];
  pathMapToMenu(userMenus, currentPath, breadcrumbs);
  return breadcrumbs;
}

export function pathMapToMenu(userMenus: any[], currentPath: string, breadcrumbs?: IBreadcrumb[]): any {
  for (const menu of userMenus) {
    if (menu.children) {
      const findMenu = pathMapToMenu(menu.children || [], currentPath);
      if (findMenu) {
        breadcrumbs?.push({ name: menu.name, path: menu.path });
        breadcrumbs?.push({ name: findMenu.name, path: findMenu.path });
        return findMenu;
      }
    } else if (!menu.children && menu.path === currentPath) {
      return menu;
    }
  }
}
