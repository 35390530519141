/**
 * 日期：2022/5/29 13:20
 * author：lsshu
 **/
import { useStore } from "@/store";

/***
 * 检查当前可操作的权限
 * @param baseUri
 * @param hanldeName
 */
export function usePermission(baseUri: string, hanldeName: string | undefined = undefined) {
  const store = useStore();
  const scopes = store.state.login.scopes;
  const verifyPermission = hanldeName ? `${baseUri}.${hanldeName}` : baseUri;
  return !!scopes?.find((item: any) => item === verifyPermission);
}
