/**
 * 日期：2022/4/30 16:45
 * author：lsshu
 **/
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";

dayjs.extend(utc);
const DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";

/***
 * 格式化 日期
 * @param utcString
 * @param format
 */
export function formatUtcString(utcString: string, format: string = DATE_TIME_FORMAT) {
  return dayjs.utc(utcString).format(format);
}

/***
 * 计算日期间的天数
 * @param startDate
 * @param enDate
 */
export function getDaysBetween(startDate: string, enDate: string) {
  return dayjs(startDate).diff(enDate, "day");
}

export function getToDay(format = "YYYY-MM-DD") {
  return dayjs().format(format);
}

export function getDaysToDayBetween(enDate: string) {
  const format = "YYYY-MM-DD";
  return getDaysBetween(formatUtcString(enDate, format), getToDay(format));
}

//  秒数转化为时分秒
export function formatSeconds(value: string) {
  if (value && (typeof value === "string" || typeof value === "number")) {
    //  秒
    let second = parseInt(value);
    //  分
    let minute = 0;
    //  小时
    let hour = 0;
    //  天
    //  let day = 0
    //  如果秒数大于60，将秒数转换成整数
    if (second > 60) {
      //  获取分钟，除以60取整数，得到整数分钟
      minute = Math.trunc(second / 60);
      //  获取秒数，秒数取佘，得到整数秒数
      second = Math.trunc(second % 60);
      //  如果分钟大于60，将分钟转换成小时
      if (minute > 60) {
        //  获取小时，获取分钟除以60，得到整数小时
        hour = Math.trunc(minute / 60);
        //  获取小时后取佘的分，获取分钟除以60取佘的分
        minute = Math.trunc(minute % 60);
        //  如果小时大于24，将小时转换成天
        //  if (hour > 23) {
        //    //  获取天数，获取小时除以24，得到整天数
        //    day = parseInt(hour / 24)
        //    //  获取天数后取余的小时，获取小时除以24取余的小时
        //    hour = parseInt(hour % 24)
        //  }
      }
    }

    let result = "" + Math.trunc(second) + "′′";
    if (minute > 0) {
      result = "" + Math.trunc(minute) + "′" + result;
    }
    if (hour > 0) {
      result = "" + Math.trunc(hour) + "h" + result;
    }
    //  if (day > 0) {
    //    result = '' + parseInt(day) + '天' + result
    //  }
    return result;
  }
}

/***
 * 计算日期间的天时分秒
 * @param startDate
 * @param enDate
 */
export function getBetweenSeconds(startDate: string, enDate: string) {
  const sec: any = dayjs(startDate).diff(enDate, "seconds");
  return formatSeconds(sec);
}
