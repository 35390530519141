/**
 * 日期：2022/7/8 18:28
 * author：lsshu
 **/
/***
 * 合并对象
 * @param o
 * @param n
 * @param override
 * @param array push replace
 */
const extend = (o: any, n: any, override = false, array = "push") => {
  for (const key in n) {
    if (!(key in o) || override) {
      if (typeof n[key] !== "object") {
        //字符串字段
        o[key] = n[key];
      }
      if (!o[key]) {
        // o 没有 直接等于 退出当前
        o[key] = n[key];
        continue;
      }
      if (Object.prototype.toString.call(n[key]) === "[object Array]") {
        // o[key] = key in o ? o[key].concat(n[key]) : n[key];
        o[key] = key in o ? (array === "push" ? [...o[key], ...n[key]] : n[key]) : n[key];
      }
      if (Object.prototype.toString.call(n[key]) === "[object Object]") {
        o[key] = extend({ ...o[key] }, { ...n[key] }, override, array);
      }
    }
  }
  return o;
};

/***
 * 数组去重
 * @param arr
 */
const unique = (arr: []) => {
  return Array.from(new Set(arr));
};

const mergeArray = (arr: any[], _arr: any[], _override = true, _unique = false) => {
  const data: any = [];
  for (let i = 0; i < arr.length + _arr.length; i++) {
    if (i in arr && i in _arr) {
      data[i] = _override ? _arr[i] : arr[i];
    } else if (i in arr) {
      data[i] = arr[i];
    } else {
      data[i] = _arr[i];
    }
  }
  return _unique ? unique(data) : data;
};

/***
 * 深度合并Object
 * @param o
 * @param _o
 * @param arrMergeType
 */
const deepObjectAssign = (o: any = {}, _o: any = {}, arrMergeType = "key") => {
  for (const key in _o) {
    if (key in o) {
      if (Object.prototype.toString.call(_o[key]) === "[object Array]" && Object.prototype.toString.call(o[key]) === "[object Array]") {
        o[key] = [...o[key]].length ? deepArrayAssign([...o[key]], [..._o[key]], arrMergeType) : [..._o[key]];
      } else if (Object.prototype.toString.call(_o[key]) === "[object Object]" && Object.prototype.toString.call(o[key]) === "[object Object]") {
        o[key] = deepObjectAssign({ ...o[key] }, { ..._o[key] });
      } else {
        o[key] = _o[key];
      }
    } else {
      o[key] = _o[key];
    }
  }
  return o;
};
/***
 * 深度合并Array
 * @param arr
 * @param _arr
 * @param mergeType
 */
const deepArrayAssign = (arr: any = [], _arr: any = [], mergeType = "key") => {
  if (mergeType === "merge") {
    return [...arr, ..._arr];
  }
  if (mergeType === "key") {
    for (const key in _arr) {
      if (key in arr) {
        if (Object.prototype.toString.call(_arr[key]) === "[object Array]" && Object.prototype.toString.call(arr[key]) === "[object Array]") {
          arr[key] = deepArrayAssign([...arr[key]], [..._arr[key]], mergeType);
        }
        if (Object.prototype.toString.call(_arr[key]) === "[object Object]" && Object.prototype.toString.call(arr[key]) === "[object Object]") {
          arr[key] = deepObjectAssign({ ...arr[key] }, { ..._arr[key] });
        }
      }
    }
  }

  return arr;
};

/***
 * 判断是否是手机端
 */
const isMobile = () =>
  Boolean(
    navigator.userAgent.match(
      /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
    )
  );
const isWeixin = () => {
  const ua: any = navigator.userAgent.toLowerCase();
  return ua.match(/MicroMessenger/i) == "micromessenger";
};
const objectToQuery = (obj: any, prefix: any = "") => {
  if (typeof obj !== "object") return "";
  const attrs = Object.keys(obj);
  return attrs.reduce((query, attr, index) => {
    // 判断是否是第一层第一个循环
    if (index === 0 && !prefix) query += "?";
    if (typeof obj[attr] === "object") {
      const subPrefix = prefix ? `${prefix}[${attr}]` : attr;
      query += objectToQuery(obj[attr], subPrefix);
    } else {
      if (prefix) {
        query += `${prefix}[${attr}]=${obj[attr]}`;
      } else {
        query += `${attr}=${obj[attr]}`;
      }
    }
    // 判断是否是第一层最后一个循环
    if (index !== attrs.length - 1) query += "&";
    return query;
  }, "");
};

/**
 * 讲对象转换为url参数形式
 * @property {Object} param 将要转换为URL参数的字符串对象
 * @property {String} key URL 参数字符串的前缀
 * @property {Boolean} encode 是否进行URL编码，默认为true
 * @return {String} URL参数字符串
 */
const urlEncode = (param: any, key: string, encode: boolean) => {
  if (param == null) return "";
  let paramStr = "";
  const t = typeof param;
  if (t == "string" || t == "number" || t == "boolean") {
    paramStr += "&" + key + "=" + (encode == null || encode ? encodeURIComponent(param) : param);
  } else {
    for (const i in param) {
      const k = key == null ? i : key + (param instanceof Array ? "[" + i + "]" : "." + i);
      paramStr += urlEncode(param[i], k, encode);
    }
  }
  return paramStr;
};
/***
 * 获取数组
 * @param start
 * @param end
 * @param step
 */
const range = (start: number, end: number, step: any = 1) => {
  return Array.from({ length: Math.ceil((end - start) / step.toFixed(2)) }, (e, i) => start + i * step);
};
/***
 * 获取 a数组里的数据在b数组里没有的值
 * @param a
 * @param b
 */
const differArray = (a: any, b: any) => a.filter((item: any) => !b.includes(item));
/***
 * 获取相不存在的值
 * @param a
 * @param b
 */
const notHaveArray = (a: any, b: any) => [].concat(differArray(a, b), differArray(b, a));

/***
 * 取得[n,m]范围随机数
 * @param n
 * @param m
 */
const fullCloseRandom = (n: number, m: number) => {
  let result = Math.random() * (m + 1 - n) + n;
  while (result > m) {
    result = Math.random() * (m + 1 - n) + n;
  }
  return Math.round(result);
};

/***
 * 取得(n,m)范围随机数
 * @param n
 * @param m
 */
const fullOpenRandom = (n: number, m: number) => {
  let result = Math.random() * (m - n) + n;
  while (result == n) {
    result = Math.random() * (m - n) + n;
  }
  return Math.round(result);
};

/***
 * 取得(n,m]范围随机数
 * @param n
 * @param m
 */
const leftOpenRandom = (n: number, m: number) => {
  let result = Math.random() * (m - n + 1) + n - 1;
  while (result < n) {
    result = Math.random() * (m - n + 1) + n - 1;
  }
  return Math.round(result);
};

/**
 * 自定义模板引擎
 * @param html <div class="cont"><% this.content %></div> 或者 document.getElementById(tmpId).innerHTML.trim();
 * @param data {content:""}
 * @returns {never}
 * @private
 */
const templateEngine = (html: string, data: any) => {
  const re = /<%([^%>]+)?%>/g;
  const reExp = /(^( )?(if|for|else|switch|case|break|{|}))(.*)?/g;
  let code = "var r=[];\n";
  let cursor = 0;
  let match;
  const add = (line: string, js: boolean) => {
    js
      ? (code += line.match(reExp) ? line + "\n" : "r.push(" + line + ");\n")
      : (code += line !== "" ? 'r.push("' + line.replace(/"/g, '\\"') + '");\n' : "");
    return add;
  };
  while ((match = re.exec(html))) {
    add(html.slice(cursor, match.index), false)(match[1], true);
    cursor = match.index + match[0].length;
  }
  add(html.substr(cursor, html.length - cursor), false);
  code += 'return r.join("");';
  return new Function(code.replace(/[\r\t\n]/g, "")).apply(data);
};
/***
 * 获取object 下的值 采用 . 连接
 * @param obj
 * @param key
 * @param value
 */
const getObjectKey = (obj: any, key: string, value: any) => {
  if (value) return value;
  key.split(".").forEach((item: any) => {
    if (typeof obj === "object") {
      obj = item in obj ? obj[item] : "";
    }
  });
  return obj;
};

const getInData = (obj: any, key: string, separator = "-") => {
  /***
   * 获取object 下的值 采用 - 连接
   * reduce 方法参数
   * prev 必需。累计器累计回调的返回值; 表示上一次调用回调时的返回值，或者初始值 init;
   * cur 必需。表示当前正在处理的数组元素；
   * index 可选。表示当前正在处理的数组元素的索引，若提供 init 值，则起始索引为- 0，否则起始索引为1；
   * arr 可选。表示原数组；
   * init 可选。表示初始值。
   */
  return key.split(separator).reduce(function (xs: any, x: any) {
    return xs && xs[x] ? xs[x] : null;
  }, obj);
};

const delInData = (obj: any, key: string, separator = "-") => {
  /***
   * 获取object 下的值 采用 - 连接
   * reduce 方法参数
   * prev 必需。累计器累计回调的返回值; 表示上一次调用回调时的返回值，或者初始值 init;
   * cur 必需。表示当前正在处理的数组元素；
   * index 可选。表示当前正在处理的数组元素的索引，若提供 init 值，则起始索引为- 0，否则起始索引为1；
   * arr 可选。表示原数组；
   * init 可选。表示初始值。
   */
  const key_arr = key.split(separator);
  for (let i = 0; i < key_arr.length; i++) {
    if (i === key_arr.length - 1) {
      if (Object.prototype.toString.call(obj) === "[object Array]") {
        const _k = isNaN(parseInt(key_arr[i])) ? key_arr[i] : parseInt(key_arr[i]);
        obj.splice(_k, 1);
      }
    } else {
      obj = obj[key_arr[i]];
    }
  }
};

const addInData = (obj: any, key: string, value: any, separator = "-", lastType = "Array") => {
  /***
   * 获取object 下的值 采用 - 连接
   * reduce 方法参数
   * prev 必需。累计器累计回调的返回值; 表示上一次调用回调时的返回值，或者初始值 init;
   * cur 必需。表示当前正在处理的数组元素；
   * index 可选。表示当前正在处理的数组元素的索引，若提供 init 值，则起始索引为- 0，否则起始索引为1；
   * arr 可选。表示原数组；
   * init 可选。表示初始值。
   */

  const key_arr = key.split(separator);
  for (let i = 0; i < key_arr.length; i++) {
    if (i === key_arr.length - 1) {
      if (Object.prototype.toString.call(obj) === "[object Array]") {
        // const _k = isNaN(parseInt(key_arr[i])) ? key_arr[i] : parseInt(key_arr[i]);
        obj.push(value);
      }
    } else if (i === key_arr.length - 2 && obj[key_arr[i]] === undefined) {
      if (lastType === "Array") {
        obj[key_arr[i]] = [];
        const cur = isNaN(parseInt(key_arr[i + 1])) ? key_arr[i + 1] : parseInt(key_arr[i + 1]);
        obj[key_arr[i]].splice(cur, 0, value);
        break;
      }
    } else {
      obj = obj[key_arr[i]];
    }
  }
};

const setInData = (key: string, value: any, separator = "-"): any => {
  /***
   * 设置object 下的值 采用 - 连接
   * reduce 方法参数
   * prev 必需。累计器累计回调的返回值; 表示上一次调用回调时的返回值，或者初始值 init;
   * cur 必需。表示当前正在处理的数组元素；
   * index 可选。表示当前正在处理的数组元素的索引，若提供 init 值，则起始索引为- 0，否则起始索引为1；
   * arr 可选。表示原数组；
   * init 可选。表示初始值。
   */

  return key
    .split(separator)
    .reverse()
    .reduce((prev: any, cur: any, index: number) => {
      let obj: any;

      if (index === 1) {
        let _obj: any;
        prev = isNaN(parseInt(prev)) ? prev : parseInt(prev);
        if (typeof prev === "number") {
          _obj = [];
          _obj.splice(prev, 0, value);
        }
        if (typeof prev === "string") {
          _obj = { [prev]: value };
        }
        cur = isNaN(parseInt(cur)) ? cur : parseInt(cur);
        if (typeof cur === "number") {
          obj = [];
          obj.splice(cur, 0, _obj);
        }
        if (typeof cur === "string") {
          obj = { [cur]: _obj };
        }
      } else {
        cur = isNaN(parseInt(cur)) ? cur : parseInt(cur);
        if (typeof cur === "number") {
          obj = [];
          obj.splice(cur, 0, prev);
        }
        if (typeof cur === "string") {
          obj = { [cur]: prev };
        }
      }
      return obj;
    });
};

export {
  extend,
  unique,
  mergeArray,
  isMobile,
  isWeixin,
  objectToQuery,
  urlEncode,
  range,
  differArray,
  notHaveArray,
  fullCloseRandom,
  fullOpenRandom,
  leftOpenRandom,
  templateEngine,
  getObjectKey,
  getInData,
  delInData,
  addInData,
  setInData,
  deepObjectAssign
};
