/**
 * 日期：2022/4/24 18:45
 * author：lsshu
 **/
import * as ElIconModules from "@element-plus/icons-vue";
import { Menu as IconMenu } from "@element-plus/icons-vue";
export default {
  install: (app: any) => {
    Object.keys(ElIconModules).forEach((key) => app.component((ElIconModules as any)[key].name, (ElIconModules as any)[key]));
    app.component("icon-menu", IconMenu);
  }
};
