/**
 * 日期：2022/5/11 15:39
 * author：lsshu
 **/
import qs from "qs";

import request from "../request";
import { ILogin } from "./types";
import { IDataType } from "../types";
import { LOGIN } from "@/constant/oauth";

export function loginRequest(data: ILogin) {
  return request.post<IDataType>({
    url: LOGIN.REQUEST_LOGIN_URI,
    data: qs.stringify(data)
  });
}

export function getScopesRequest() {
  return request.get<IDataType>({
    url: LOGIN.REQUEST_GET_SCOPES_URI
  });
}

export function getMenusRequest() {
  return request.get<IDataType>({
    url: LOGIN.REQUEST_GET_MENUS_URI
  });
}
