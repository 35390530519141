/**
 * 日期：2022/4/30 17:13
 * author：lsshu
 * 开发环境: development
 * 生成环境: production
 * 测试环境: test
 **/
import { REQUEST } from "@/constant/config";

const BASE_URL = REQUEST.REQUEST_BASE_URL;
const TIME_OUT = REQUEST.REQUEST_TIME_OUT;

export { BASE_URL, TIME_OUT };
