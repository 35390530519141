/**
 * 日期：2022/5/7 17:12
 * author：lsshu
 **/
/*请求相关*/
const REQUEST = {
  REQUEST_DATA_KEY: "uuid",
  REQUEST_AUTHORIZATION_KEY: "Authorization",
  REQUEST_AUTHORIZATION_TYPE: "Bearer",
  REQUEST_BASE_URL: process.env.VUE_APP_API_BASE_URL, // 请求根路径
  REQUEST_TIME_OUT: 6000000 // 请求超时时间
};
const KEY_TEXT = {
  KEY_TOKEN: "access_token", // 缓存token键
  KEY_MENUS: "menus", // 缓存菜单键
  KEY_SCOPES: "scopes", // 缓存权限键
  KEY_TAG_VIEWS: "tag_view", // 缓存菜单键
  KEY_RESPONSE_PREVIEW: "preview_path", // 上传返回预览地址键
  KEY_RESPONSE_PATH: "path" // 上传返回地址键
};
const REDIRECT = {
  LOGIN_SUCCESS_REDIRECT_URI: "main", // 登录跳转到的uri
  SCOPE_401_REDIRECT_ROUTE_NAME: "login" // 登录失效跳转到的路由name
};

export { REQUEST, KEY_TEXT, REDIRECT };
