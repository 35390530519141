/**
 * 日期：2022/5/28 15:28
 * author：lsshu
 **/
import { Module } from "vuex";
import { IPageState, IPayload } from "./types";
import { IRootState } from "@/store/types";
import { deleteData, getData, patchData, postData } from "@/service/default/page";
import { extend, isMobile } from "@/utils/tool";
import { REQUEST } from "@/constant/config";
import Crypoto from "@/utils/crypto";

/***
 * 默认数据结构
 */
const defaultPage = () => ({
  ids: [],
  params: {},
  items: [],
  total: 0,
  quest: {
    params: {
      currentPage: 1,
      currentLimit: 50,
      pageSizes: [18, 30, 50, 100, 500, 1000]
    },
    data: {
      sort: [{ id: "desc" }],
      where: []
    }
  },
  isFilter: !isMobile()
});

const pageModule: Module<IPageState, IRootState> = {
  namespaced: true,
  state() {
    return {
      page: {}
    };
  },
  getters: {},
  mutations: {
    /***
     * 初始化page数据
     * @param state
     * @param data
     */
    initPage(state, data: any) {
      const _data = extend({ ...defaultPage() }, data.data || {}, true, "replace");
      state.page[data.page] || (state.page[data.page] = { ..._data });
      /*初始化hash参数*/
      if (!location.hash && state.page[data.page] && state.page[data.page]?.quest.data.where) {
        location.hash = Crypoto.encrypt(state.page[data.page]?.quest.data.where);
      }
    },
    /***
     * 设置列表数据
     * @param state
     * @param data
     */
    changeItemsAndTotal(state, data: any) {
      const pageObj = state.page[data.page] || (state.page[data.page] = { ...defaultPage });
      pageObj.items = data.items;
      pageObj.total = data.total;
    },
    /***
     * 设置分页
     * @param state
     * @param data
     */
    changeQuestPage(state, data: any) {
      const pageObj = state.page[data.page] || (state.page[data.page] = { ...defaultPage });
      pageObj.quest.params = {
        currentPage: data.currentPage,
        currentLimit: data.currentLimit,
        pageSizes: data.pageSizes
      };
    },
    /***
     * 设置排序
     * @param state
     * @param data
     */
    changeSort(state, data: any) {
      const pageObj = state.page[data.page] || (state.page[data.page] = { ...defaultPage });
      /*取消排序*/
      const sortObj = pageObj.quest.data?.sort || [];
      const _sort = sortObj.filter((item: any) => item[data.data.key] === undefined);
      /*添加*/
      if (data.data.value !== undefined) _sort.unshift(data.sort);
      pageObj.quest.data.sort = _sort;
    },
    /***
     * 添加过滤条件
     * @param state
     * @param data
     */
    changeAddWhere(state, data: any) {
      const pageObj = state.page[data.page] || (state.page[data.page] = { ...defaultPage });
      const whereObj = pageObj.quest.data?.where || [];
      if (data.data instanceof Array) {
        const key = data.data.map((item: any) => item.key);
        const _where = whereObj.filter((item: any) => !key.includes(item.key));
        pageObj.quest.data.where = data.data.concat(_where);
      } else if (data.data instanceof Object) {
        const key = [data.data].map((item: any) => item.key);
        const _where = whereObj.filter((item: any) => !key.includes(item.key));
        pageObj.quest.data.where = [data.data].concat(_where);
      }
      location.hash = Crypoto.encrypt(pageObj.quest.data.where);
    },
    /***
     * 删除过滤条件
     * @param state
     * @param data
     */
    changeRemoveWhere(state, data: any) {
      const pageObj = state.page[data.page] || (state.page[data.page] = { ...defaultPage });
      const whereObj = pageObj.quest.data?.where || [];
      if (data.filter instanceof Array) {
        const _where = whereObj.filter((item: any) => !data.filter.includes(item.key));
        if (_where.length < whereObj.length) {
          pageObj.quest.data.where = _where;
        }
      }
      if (data.filter instanceof String) {
        const _where = whereObj.filter((item: any) => item.key !== data.filter);
        if (_where.length < whereObj.length) {
          pageObj.quest.data.where = _where;
        }
      }
      location.hash = "";
    },
    /***
     * 设置其它数据
     * @param state
     * @param data
     */
    changeParams(state, data: any) {
      const pageObj = state.page[data.page] || (state.page[data.page] = { ...defaultPage });
      pageObj.params = data.params;
    },
    /***
     * 设置选择的ids
     * @param state
     * @param data
     */
    changeIds(state, data: any) {
      const pageObj = state.page[data.page] || (state.page[data.page] = { ...defaultPage });
      pageObj.ids = data.ids;
    },
    /***
     * 切换是否过滤
     * @param state
     * @param data
     */
    isFilter(state, data: any) {
      const pageObj = state.page[data.page] || (state.page[data.page] = { ...defaultPage });
      pageObj.isFilter = !pageObj.isFilter;
    }
  },
  actions: {
    /***
     * 请求数据 get
     * @param commit
     * @param state
     * @param page
     * @param uri
     * @param isTree
     * @param treeUri
     */
    async getDataAction({ commit, state }, { page, uri, isTree, treeUri = "" }: IPayload) {
      const quest_params = state.page[`${page}`]?.quest.params || {};
      const quest_data = state.page[`${page}`]?.quest.data || {};
      const request_uri = isTree && treeUri ? treeUri : uri;
      const pageResult = await getData(request_uri, {
        where: quest_data.where,
        order: quest_data.sort,
        page: quest_params.currentPage,
        limit: quest_params.currentLimit
      });
      const { items, total } = pageResult.data.items ? pageResult.data : { items: pageResult.data, total: 0 };
      commit("changeItemsAndTotal", { page, items, total });
    },
    /***
     * 请求数据 get
     * @param commit
     * @param state
     * @param page
     * @param uri
     * @param isTree
     * @param treeUri
     */
    async getValueDataAction({ commit, state }, { page, uri, isTree, treeUri = "" }: IPayload) {
      const quest_params = state.page[`${page}`]?.quest.params || {};
      const quest_data = state.page[`${page}`]?.quest.data || {};
      const request_uri = isTree && treeUri ? treeUri : uri;
      const pageResult = await getData(request_uri, {
        where: { value: quest_data.where },
        order: { value: quest_data.sort },
        page: { value: quest_params.currentPage },
        limit: { value: quest_params.currentLimit }
      });
      if (pageResult && pageResult.data) {
        const { items, total } = pageResult.data.items ? pageResult.data : { items: pageResult.data, total: 0 };
        commit("changeItemsAndTotal", { page, items, total });
      }
    },
    /***
     * 请求数据 post
     * @param commit
     * @param rootState
     * @param page
     * @param uri
     * @param params
     */
    async postDataAction({ commit, state }, { page, uri, isTree, treeUri = "" }: IPayload) {
      const quest_params = state.page[`${page}`]?.quest.params || {};
      const quest_data = state.page[`${page}`]?.quest.data || {};
      const request_uri = isTree && treeUri ? `${treeUri}.post` : `${uri}.post`;
      const pageResult = await postData(request_uri, {
        where: quest_data.where,
        order: quest_data.sort,
        page: quest_params.currentPage,
        limit: quest_params.currentLimit
      });
      const { items, total } = pageResult.data.items ? pageResult.data : { items: pageResult.data, total: 0 };
      commit("changeItemsAndTotal", { page, items, total });
    },
    /***
     * 更新或者创建信息
     * @param dispatch
     * @param page
     * @param uri
     * @param data
     */
    async createOrUpdateDataAction({ dispatch }, { page, uri, data, reloadUri }: IPayload) {
      const response = await (!data[REQUEST.REQUEST_DATA_KEY] ? postData(uri, data) : patchData(`/${uri}/${data[REQUEST.REQUEST_DATA_KEY]}`, data));
      if (response.code === 0) {
        // 请求最新的数据
        dispatch("getValueDataAction", { page: reloadUri ? reloadUri : page, uri: reloadUri ? reloadUri : uri });
      }
      return response;
    },
    /***
     * 批量更新数据
     * @param dispatch
     * @param page
     * @param uri
     * @param data
     * @param reloadUri
     */
    async batchUpdateDataAction({ dispatch }, { page, uri, data, reloadUri }: IPayload) {
      const response = await patchData(`/${uri}`, data, { ids: data.ids.join(",") });
      if (response.code === 0) {
        // 请求最新的数据
        dispatch("getValueDataAction", { page: reloadUri ? reloadUri : page, uri: reloadUri ? reloadUri : uri });
      }
      return response;
    },
    /***
     * 删除信息
     * @param dispatch
     * @param page
     * @param uri
     * @param pk
     */
    async deleteDataAction({ dispatch }, { page, uri, pk }: IPayload) {
      const url = `/${uri}/${pk}`;
      const response = await deleteData(url);
      if (response.code === 0) {
        // 请求最新的数据
        dispatch("getValueDataAction", { page, uri });
      }
      return response;
    },
    async batchDeleteDataAction({ dispatch }, { page, uri, ids }: IPayload) {
      const url = `/${uri}`;
      const response = await deleteData(url, ids);
      if (response.code === 0) {
        // 请求最新的数据
        dispatch("getValueDataAction", { page, uri });
      }
      return response;
    },
    async getDataParamsAction({ commit }, { page, uri }: IPayload) {
      uri = `${uri}.params`;
      const pageResult = await getData(uri, {});
      pageResult && pageResult.data && commit("changeParams", { page, params: pageResult.data });
    },
    /***
     * 导出
     * @param commit
     */
    async exportAction({ state }, { page, uri }: IPayload) {
      uri = `${uri}.export`;
      const quest_params = state.page[`${page}`]?.quest.params || {};
      const quest_data = state.page[`${page}`]?.quest.data || {};

      getData(uri, {
        where: { value: quest_data.where },
        order: { value: quest_data.sort },
        page: { value: quest_params.currentPage },
        limit: { value: quest_params.currentLimit }
      }).then((res) => {
        if (res.code === 0) {
          window.open(res.data, "_blank");
        }
      });
    },
    /***
     * 切换是否列出过滤
     * @param commit
     */
    async filterAction({ commit }, { page }: IPayload) {
      commit("isFilter", { page });
    }
  }
};

export default pageModule;
