/**
 * 日期：2022/5/31 13:58
 * author：lsshu
 **/
import { IForm } from "components/form/types";

export const defaultFormConfig: IForm = {
  formProps: {
    labelWidth: "60px"
  },
  colItems: [
    {
      field: "username",
      type: "LInput",
      formProps: {
        disabled: true
      },
      itemProps: {
        label: "名称",
        prop: "username",

        rules: [
          {
            required: true
          }
        ]
      }
    },
    {
      field: "password",
      type: "LInput",
      itemProps: {
        label: "密码",
        prop: "password"
      },
      formProps: {
        password: true
      }
    }
  ]
};
