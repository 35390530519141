/**
 * 日期：2022/12/2 18:16
 * author：ke
 **/
import { isMobile } from "@/utils/tool";

export const getmark = () => {
  const setWatermark = (name: string, phone: string) => {
    const id = "1.23452384164.123412416";
    if (document.getElementById(id) !== null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      document.body.removeChild(document.getElementById(id)!);
    }
    //创建一个画布
    const can = document.createElement("canvas");
    //设置画布的长宽
    can.width = 300;
    can.height = 200;
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const cans = can.getContext("2d")!;
    //旋转角度
    cans.rotate((-12 * Math.PI) / 180);
    cans.font = "14px arial";
    //设置填充绘画的颜色、渐变或者模式
    cans.fillStyle = "rgba(50, 50, 50, 0.08)";
    //设置文本内容的当前对齐方式
    cans.textAlign = "left";
    //设置在绘制文本时使用的当前文本基线
    //cans.textBaseline = "Middle";
    //在画布上绘制填色的文本（输出的文本，开始绘制文本的X坐标位置，开始绘制文本的Y坐标位置）
    cans.fillText(name, can.width / 8, can.height / 4);
    cans.fillText(phone, can.width / 2.2, can.height);
    const div = document.createElement("div");
    div.id = id;
    div.style.pointerEvents = "none";
    div.style.top = isMobile() ? "0px" : "30px";
    div.style.left = isMobile() ? "0px" : "230px";
    div.style.position = "fixed";
    div.style.zIndex = "100000";
    div.style.width = document.documentElement.clientWidth + "px";
    div.style.height = document.documentElement.clientHeight + "px";
    div.style.background = "url(" + can.toDataURL("image/png") + ") left top repeat";
    document.body.appendChild(div);
    return id;
  };

  // 该方法只允许调用一次

  const watermark = (name: string, phone: string) => {
    let id = setWatermark(name, phone);

    setInterval(() => {
      if (document.getElementById(id) === null) {
        id = setWatermark(name, phone);
      }
    }, 500);

    window.onresize = () => {
      setWatermark(name, phone);
    };
  };

  return { watermark };
};
