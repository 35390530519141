// import "@babel/polyfill";
import "core-js";
import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store, { setupStore } from "./store";
import Icon from "@/plugins/icons";
import globalRegister from "@/global";
// 导入全局样式
import "normalize.css";
import "./assets/css/index.scss";
setupStore();
createApp(App).use(Icon).use(store).use(globalRegister).use(router).mount("#app");
