/**
 * 日期：2022/5/11 15:09
 * author：lsshu
 **/
import { Module } from "vuex";
import { ILoginState } from "./types";
import { IRootState } from "@/store/types";
import { ILogin } from "@/service/login/types";
import { getMenusRequest, getScopesRequest, loginRequest } from "@/service/login";
import { KEY_TEXT, REDIRECT } from "@/constant/config";

import localCache from "@/utils/localcache";
import router from "@/router";
import { mapMenusToRoutes } from "@/utils/map-menus";

const loginModule: Module<ILoginState, IRootState> = {
  namespaced: true,
  state() {
    return {
      access_token: false,
      user: {},
      scopes: [],
      menus: []
    };
  },
  getters: {},
  mutations: {
    changeToken(state, token: string) {
      state.access_token = token;
    },
    changeUser(state, user: any) {
      state.user = user;
    },
    changeScopes(state, scopes: any) {
      state.scopes = scopes;
    },
    changeMenus(state, menus: any) {
      state.menus = menus;
      const routes = mapMenusToRoutes(menus);
      routes.forEach((route) => {
        router.addRoute("main", route);
      });
    }
  },
  actions: {
    /***
     * 用户登录操作
     * @param commit
     * @param payload
     */
    loginAction({ commit, dispatch }, payload: ILogin) {
      //  1.请求登录
      loginRequest(payload).then((res) => {
        if (res && res.data) {
          const { access_token } = res.data;
          commit("changeToken", access_token);
          localCache.set(KEY_TEXT.KEY_TOKEN, access_token);
          dispatch("getMenusAction"); // 获取菜单
          dispatch("getScopesAction"); // 获取授权信息
          router.push(REDIRECT.LOGIN_SUCCESS_REDIRECT_URI);
        }
      });
    },
    async logoutAction({ commit }) {
      localCache.delete(KEY_TEXT.KEY_TOKEN);
      commit("changeToken", false);
    },
    /***
     * 获取权限
     * @param commit
     */
    getScopesAction({ commit }) {
      getScopesRequest().then((res) => {
        if (res && res.data) {
          const { scopes, user } = res.data;
          localCache.set(KEY_TEXT.KEY_SCOPES, scopes);
          commit("changeScopes", scopes);
          commit("changeUser", user);
        }
      });
    },
    /***
     * 获取菜单
     * @param commit
     */
    async getMenusAction({ commit }) {
      const menusResult = await getMenusRequest();
      commit("changeMenus", menusResult.data);
      localCache.set(KEY_TEXT.KEY_MENUS, menusResult.data);
    },
    /***
     * 加载刷新数据
     * @param commit
     */
    loadLocalLogin({ commit, dispatch }) {
      const access_token = localCache.get(KEY_TEXT.KEY_TOKEN);
      if (access_token) {
        // 加载token
        commit("changeToken", access_token);
        dispatch("getScopesAction"); // 获取授权信息
      }
      const menus = localCache.get(KEY_TEXT.KEY_MENUS);
      if (menus) {
        // 加载菜单
        commit("changeMenus", menus);
      }
      const scopes = localCache.get(KEY_TEXT.KEY_SCOPES);
      if (scopes) {
        // 加载权限
        commit("changeScopes", scopes);
      }
    }
  }
};

export default loginModule;
