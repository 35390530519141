import { createStore, Store, useStore as useVuexStore } from "vuex";
import { IStoreType } from "./types";
import global from "./modules/global";
import page from "./modules/page";
import login from "./modules/login";

const store = createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    global,
    page,
    login
  }
});
export function useStore(): Store<IStoreType> {
  return useVuexStore();
}
export function setupStore() {
  store.dispatch("login/loadLocalLogin");
}

export default store;
