const ACCOUNT = {
  DASHBOARD: "App.stats.accounts",
  AGENT: "App.stats.account.agents",
  MARKET: "App.stats.account.markets",
  PROMOTION: "App.stats.account.promotions"
};
const USER = {
  SALE: "App.stats.user.sales",
  GROUP: "App.stats.user.groups",
  WORKING: "App.stats.user.workings",
  PROBABILITY: "App.stats.user.probabilities"
};
const PAGE = {
  EXAMINE: "App.stats.page.examines",
  TEMPLATE: "App.stats.page.copywrites.template",
  VIEW: "App.stats.page.views",
  SERVER: "App.stats.page.servers",
  COPYWRITE: "App.stats.page.copywrites",
  HUMEN: "App.stats.page.humen"
};
const DATA = {
  DASHBOARD: "App.stats.datas",
  VISITOR: "App.stats.data.visitors",
  SALE: "App.stats.data.sales",
  PAGE: "App.stats.data.pages",
  GLANCE: "App.stats.data.glances",
  COPIE: "App.stats.data.copies",
  MESSAGE: "App.stats.data.messages"
};
export { ACCOUNT, USER, PAGE, DATA };
